import React, { useEffect, useRef, useState } from 'react'
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input } from "antd";
import MeetServices from "../../services/MeetServices";
import Loading from "../../components/shared-components/Loading";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ConferenceServices from 'services/ConferenceServices';

const ConferenceRoom = (props => {
    const { location } = props
    const [form] = Form.useForm();
    const [hasAi, setHasAi] = useState(false);
    const [hide, setHide] = useState(false);
    const { username } = useSelector(state => ({
        username: state.auth.username,
    }));
    const [discussion, setDiscussion] = useState('');
    const { TextArea } = Input;
    const AiModal = location.state?.hasAi?.hasAi || false;
    const [displayName, setDisplayName] = useState(username);
    const history = useHistory()
    const YOUR_DOMAIN = 'tunimeet.francecentral.cloudapp.azure.com'

    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('id');

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
    }
    const handleHide = (e) => {
        setHide(!hide)
    }
    const handleSaveDiscussion = (text) => {
        setDiscussion(text)
    }
    const discussionRef = useRef(discussion); // Create a ref
    useEffect(() => {
        ConferenceServices.getConference(ref).then((res) => {
            if (res.code === 200) {
                ConferenceServices.joinConference(ref)
                    .then(resp => {
                        console.log('log')
                    })
                    .catch(error => {
                        console.error('Error joining conference:', error);
                    });
            }
        })
    }, []);

    useEffect(() => {
        discussionRef.current = discussion; // Update the ref whenever discussion changes
    }, [discussion]);
    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }
    return (
        <>
            {(AiModal || hasAi) &&
                <>
                    <>
                        {hide && <div>
                            <TextArea
                                value={transcript}
                                className='bg-white position-absolute ml-4'
                                style={{ 'width': '15rem', 'top': '25vh', 'height': '50%' }}
                            >
                            </TextArea>
                        </div>}
                        <div className='position-absolute ml-4' style={{ 'top': '80vh' }}>
                            <p className='text-white'>AI detector : {listening ? 'on' : 'off'}</p>
                            <Button className='mr-1' onClick={handleHide}>{hide ? "Hide" : "Show"} Ai model</Button>
                            {hide && <>
                                <Button className='mr-1' onClick={() => { handleSaveDiscussion(transcript) }}>Save</Button>
                                <Button className='mr-1' onClick={startListening}>Start</Button>
                                <Button className='mr-1' onClick={SpeechRecognition.stopListening}>Stop</Button>
                            </>
                            }
                        </div>
                    </>
                </>
            }
            <JitsiMeeting
                domain={YOUR_DOMAIN}
                roomName={ref}
                spinner={() => <Loading cover="page" />}
                configOverwrite={{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                }}
                userInfo={{
                    displayName: displayName
                }}
                onApiReady={(externalApi) => {
                    // here you can attach custom event listeners to the Jitsi Meet External API
                    // you can also store it locally to execute commands
                    // listen for an event
                    externalApi.addEventListener('readyToClose', () => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: ref,
                            username: email,
                            discussion: discussionRef.current
                        };
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            history.push('/auth/login')
                        })
                    })

                    externalApi.addEventListener('videoConferenceLeft', (obj) => {
                        const email = username !== "" ? username : form.getFieldValue('email');
                        const meetingInfo = {
                            meetRef: obj.roomName,
                            username: email,
                            discussion: discussionRef.current
                        };
                        MeetServices.endMeeting(meetingInfo).then(() => {
                            history.push('/auth/login')
                        })
                    })
                }}
                getIFrameRef={(iframeRef) => { iframeRef.style.height = '100vh'; }}
            />
        </>
    )
})
export default ConferenceRoom
