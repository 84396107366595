import React, { useEffect, useRef, useState } from 'react'
import Loading from "../../components/shared-components/Loading";
import ConferenceServices from 'services/ConferenceServices';
import RoomMeet from './meetRoom';
import ConferenceRoom from './conferenceRoom';
import Message from './message';

const Room = (props => {
    const { location } = props
    const [message, setMessage] = useState('');
    const [isConference, setIsConference] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('id');


    useEffect(() => {
        ConferenceServices.getConference(ref).then((res) => {
            if (res.code === 200) {
                if (res.data.meet) {
                    setIsConference(true);
                    ConferenceServices.joinConference(ref)
                        .then(resp => {
                            if (resp.data.meet) {
                                setIsStarted(resp.data.meet.isStart)
                                setIsLoading(false);
                            } else {
                                setMessage(resp.data[0])
                                setIsLoading(false);
                            }
                        })
                        .catch(error => {
                            console.error('Error joining conference:', error);
                        });
                } else {
                    setIsLoading(false);
                }
            }
        })
    }, []);

    return (
        <>
            {isLoading &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Loading loading={isLoading} />
                </div>
            }
            {
                isConference ? (
                    isStarted ? (
                        <ConferenceRoom location={location} />
                    ) : (
                        <Message message={message} />
                    )
                ) : (
                    <RoomMeet location={location}  ></RoomMeet>
                )
            }

        </>
    )
})
export default Room
